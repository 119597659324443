// Carolina React Admin Dashboard with Material-UI PRO Components

@import "components/Avatars/base";
@import "components/Badges/base";
@import "components/Buttons/base";
@import "components/Cards1/base";
@import "components/Cards2/base";
@import "components/Cards5/base";
@import "components/Dropdowns/base";
@import "components/RegularTables1/base";
@import "components/FormsWizard/base";
@import "components/GuidedTours/base";
@import "components/ListGroups/base";
@import "components/FeatureSections/base";
@import "components/ProgressBars/base";
@import "components/Scrollable/base";
@import "components/Timelines/base";
@import "components/Badges/BadgesDots/base";
@import "components/Badges/BadgesDotsPositions/base";
@import "components/Buttons/ButtonsGradients/base";
@import "components/Cards1/Cards1Examples2/base";
@import "components/Cards7/Cards7Examples46/base";
@import "components/Dropdowns/DropdownsIntegrations/base";
@import "components/Icons/IconsFlags/base";
@import "components/Modals/ModalsColors/base";
@import "components/NavigationMenus/NavigationMenusVerticalBasic/base";
@import "components/NavigationMenus/NavigationMenusGrid/base";
@import "components/Notifications/NotificationsAlertsAlternateStyle/base";
@import "components/FooterSections/FooterSections1/base";
@import "components/HeaderSections/HeaderSections1/base";
@import "components/HeroSections/HeroSections1/base";
@import "components/Ribbons/RibbonsHorizontal/base";
@import "components/Ribbons/RibbonsVertical/base";
@import "components/Ribbons/RibbonsAngle/base";
@import "components/Timelines/TimelinesColors/base";
@import "components/Timelines/TimelinesHorizontal/base";
@import "components/Popovers/PopoversIntegrations/base";

@media print {
 .no-print {
    display: none;
  }
}

