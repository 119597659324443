//  Core

.table {
  font-size: $font-size-base;

  thead th {
    text-transform: uppercase;
    background: $gray-200;
    font-size: $font-size-sm;
  }

  tr {
    transition: $transition-base;

    &.table-danger{
      color: rgb(99, 20, 27);
      background-color: rgb(254, 234, 236);
    }
    &.table-warning{
      color: rgb(97, 47, 18);
      background-color: rgb(253, 241, 234);
    }

    &.default{
      color: rgb(10, 80, 26);
      background-color: rgb(232, 249, 236) !important;
    }
  }

  td, th {
    vertical-align: middle;
  }

  &.table-alternate {
    thead th {
      text-transform: none;
      background: transparent;
      font-size: $font-size-base * 1.1;
      font-weight: normal;
      color: $gray-900;
    }
  }
}


